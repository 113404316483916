import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';
import i18n from '../../../../../i18n';
import { useHistory } from 'react-router-dom';

import { Text, TextTypography } from 'components/Text/Text';
import { Button, ButtonColor } from 'components/Button/Button';

import { makeFeedLocationOpenedAction } from 'state/feed/actions';
import { useAction } from 'core/store/store';

import { ReactComponent as StoreIcon } from 'assets/icons/main-24/store--24.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/main-16/long-arrow-right--16.svg';

import css from './EmptyFeed.module.css';

export const EmptyFeed = () => {
    const { t } = i18n.useTranslation();
    const history = useHistory();
    const action = useAction();

    const onChangeAddressClick = useCallback(() => {
        action(makeFeedLocationOpenedAction);
    }, [history]);

    return (
        <div className={css.container}>
            <div className={css.staticContainer}>
                <SvgIcon className={css.icon} component={StoreIcon} viewBox="0 0 64 64" />
                <Text typography={TextTypography.LEAD_ACCENT}>{t('feed:feed.emptyFeed')}</Text>
                <SvgIcon className={css.iconSmall} component={RightArrowIcon} viewBox="0 0 16 16" />
            </div>
            <Button onClick={onChangeAddressClick} color={ButtonColor.DARK} className={css.button}>
                {t('feed:feed.changeAddress')}
                <SvgIcon className={css.iconSmall} component={RightArrowIcon} viewBox="0 0 16 16" />
            </Button>
        </div>
    );
};
