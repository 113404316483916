import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { makeAddressValidator } from 'core/validators/validators';
import { useFormHasAsyncError } from 'core/form/form';

import { Text, TextTypography } from 'components/Text/Text';
import { Form } from 'forms/types';
import { makeStyles } from '@material-ui/core/styles';
import { FormAddress } from 'components/FormAddress/FormAddress';
import { useAction } from 'core/store/store';

import { feedAddressSelector, feedCoordinatesSelector, feedIsLocationOpenSelector } from 'state/feed/selectors';
import {
    makeFeedLocationClosedAction,
    makeFeedSetAddressAction,
    makeFeedSetAddressChangedAction,
    makeFeedSetCoordinatesAction,
    makeFeedSetStandardAddressDataAction,
} from 'state/feed/actions';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { HorizontalDivider } from 'components/HorizontalDivider/HorizontalDivider';
import { ReactComponent as SendIcon } from 'assets/icons/main-16/send--16.svg';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as SubmitIcon } from 'assets/icons/status/check-square--16.svg';
import { AddressDto } from '../../types';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.between('sm', 'lg')]: {
            flexDirection: 'row',
            padding: theme.spacing(4, 5),
        },
        [theme.breakpoints.down(641)]: {
            padding: theme.spacing(4, 3),
        },
        [theme.breakpoints.down(481)]: {
            padding: theme.spacing(2, 3),
        },
        [theme.breakpoints.down(321)]: {
            padding: theme.spacing(2, 2),
        },
    },
    icon: {
        display: 'none',
        height: 120,
        width: 120,
        borderRadius: 60,
        background: theme.palette.brandPrimary.lightest,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: theme.spacing(3),
        },
    },
    container: {
        maxWidth: '100%',
    },
    controls: {
        flex: 1.5,
        [theme.breakpoints.down(961)]: {
            flex: 1.25,
        },
        [theme.breakpoints.down(769)]: {
            flex: 1.5,
        },
    },
    titleText: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    text: {
        [theme.breakpoints.between(640, 959)]: {
            marginRight: theme.spacing(5),
        },
        [theme.breakpoints.up(960)]: {
            marginRight: theme.spacing(4),
        },
        flex: 1,
        paddingBottom: theme.spacing(2),
    },
    footerButtons: {
        marginTop: theme.spacing(2),
    },
}));

const useMapStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(8),
        paddingTop: theme.spacing(10),
    },
    icon: {
        height: 120,
        width: 120,
        borderRadius: 60,
        background: theme.palette.brandPrimary.lightest,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        maxWidth: '100%',
    },
    controls: {
        flex: 1.5,
    },
    titleText: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    text: {
        flex: 1,
        paddingBottom: theme.spacing(3),
    },
    footerButtons: {
        marginTop: theme.spacing(2),
    },
}));

interface LocationProps {
    mapMode: boolean;
}

interface LocationFormData {
    address: string;
    coordinates: { lat: number; lon: number; qc_geo?: number };
    standardAddress: AddressDto;
}

const formSelector = formValueSelector(Form.CURRENT_LOCATION_FORM);
const coordinatesSelector = <T extends {}>(state: T) => formSelector(state, 'coordinates');

const CurrentLocationForm = reduxForm<LocationFormData, LocationProps>({
    form: Form.CURRENT_LOCATION_FORM,
    asyncValidate: makeAddressValidator(Form.CURRENT_LOCATION_FORM, 'address'),
    asyncChangeFields: ['address', 'coordinates'],
})(props => {
    const { form, handleSubmit, mapMode } = props;
    const classes = mapMode ? useMapStyles() : useStyles();
    const action = useAction();
    const hasError = useFormHasAsyncError(Form.CURRENT_LOCATION_FORM);

    const { t } = i18n.useTranslation();

    const initialAddress = useSelector(feedAddressSelector);
    const initialCoordinates = useSelector(feedCoordinatesSelector);
    const isLocationOpen = useSelector(feedIsLocationOpenSelector);

    const handleCoordinatesChange = useCallback(
        (coordinates: { lat: number; lon: number }) => {
            action(change, form, 'coordinates', coordinates);
        },
        [action, form],
    );

    const handleStandardAddressChange = useCallback(
        (data: AddressDto) => {
            action(change, form, 'standardAddress', data);
        },
        [action, form],
    );

    const coordinates = useSelector(coordinatesSelector);

    return (
        <div>
            {isLocationOpen && (
                <div>
                    <form onSubmit={handleSubmit} className={classes.root}>
                        {window.innerHeight > 680 && (
                            <div className={classes.icon}>
                                <Icon component={SendIcon} size={64} svgSize={16} />
                            </div>
                        )}
                        {mapMode && (
                            <div className={classes.titleText}>
                                <Text typography={TextTypography.DISPLAY_STRONG}>
                                    {t('common:addressDialog:address')}
                                </Text>
                            </div>
                        )}
                        <div className={classes.text}>
                            <Text typography={TextTypography.BODY}>{t('common:addressDialog:confirmAddress')} </Text>
                        </div>
                        <div className={classes.controls}>
                            <div className={classes.container}>
                                <Field
                                    name="address"
                                    component={FormAddress}
                                    label={t('common:addressControl.streetAddress')}
                                    initialCoordinates={initialCoordinates}
                                    value={initialAddress}
                                    buttonText="OK"
                                    onCoordinatesChange={handleCoordinatesChange}
                                    onStandardAddressChange={handleStandardAddressChange}
                                    headerText={t('common:addressDialog.enterShopAddress')}
                                    coordinates={coordinates}
                                    selectControlDropdownWidth={568}
                                />
                            </div>
                            <div className={classes.footerButtons}>
                                <LargeButton
                                    color={hasError && LargeButtonColor.LIGHT}
                                    disabled={hasError}
                                    icon={SubmitIcon}
                                    type="submit"
                                >
                                    {t('common:addressDialog:save')}
                                </LargeButton>
                            </div>
                        </div>
                    </form>
                    {!mapMode && <HorizontalDivider />}
                </div>
            )}
        </div>
    );
});

export const CurrentLocation = (props: LocationProps) => {
    const action = useAction();
    const initialAddress = useSelector(feedAddressSelector);
    const initialCoordinates = useSelector(feedCoordinatesSelector);

    const initialValues = { address: initialAddress, coordinates: initialCoordinates };

    const handleSubmit = useCallback(
        (data: LocationFormData) => {
            // See ANY-2588:
            if (data.address && data.coordinates.lat !== null && data.coordinates.lon !== null) {
                action(makeFeedSetAddressAction, data.address, '', true);
                action(
                    makeFeedSetCoordinatesAction,
                    Number(data.coordinates.lat),
                    Number(data.coordinates.lon),
                    Number(data.coordinates.qc_geo),
                );
                action(makeFeedSetStandardAddressDataAction, data.standardAddress);
                action(makeFeedSetAddressChangedAction);
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            }
            action(makeFeedLocationClosedAction);
        },
        [initialCoordinates],
    );
    return <CurrentLocationForm mapMode={props.mapMode} onSubmit={handleSubmit} initialValues={initialValues} />;
};
