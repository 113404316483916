import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Text, TextTypography } from 'components/Text/Text';
import { getOrientation } from 'core/utils/utils';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    container: {
        top: 0,
        left: 0,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        backgroundColor: '#FBFEF9',
        zIndex: 1000000,
        height: '100vh',
        width: '100vw',
    },
}));

export const OrientationError: FC = ({ children }) => {
    const classes = useStyles();
    const [orientation, setOrientation] = useState<string | number>(getOrientation());
    const [height, setHeight] = useState(window.innerHeight);
    const { t } = i18n.useTranslation();

    window.addEventListener('orientationchange', () => setOrientation(getOrientation()));
    window.addEventListener('resize', () => setHeight(window.innerHeight));

    return (
        <>
            {(orientation !== 'landscape-secondary' &&
                orientation !== 'landscape-primary' &&
                orientation !== 90 &&
                orientation !== -90) ||
            height > 550 ? (
                <>{children}</>
            ) : (
                <div className={classes.container}>
                    <Text typography={TextTypography.LEAD_ACCENT}>{t('common:errors.rotatePhone')}</Text>
                </div>
            )}
        </>
    );
};
