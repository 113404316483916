import React, { FC } from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Text, TextTypography } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { ReactComponent as WhatsappIcon } from 'assets/icons/social/whatsapp.svg';
import { appConfig } from 'config/app';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    icon: {
        height: 32,
        width: 32,
        borderRadius: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    container: {
        cursor: 'pointer',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface WhatsappAskQuestionProps {
    className?: string;
    marketplacePhoneNumber?: string;
}

export const WhatsappAskQuestion: FC<WhatsappAskQuestionProps> = ({ className, marketplacePhoneNumber }) => {
    const classes = useStyles();
    const { t } = i18n.useTranslation();

    return (
        <a
            href={`https://wa.me/${marketplacePhoneNumber || appConfig.marketplacePhoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(classes.container, className)}
        >
            <div className={classes.icon}>
                <Icon component={WhatsappIcon} svgSize={32} size={32} />
            </div>
            <Text typography={TextTypography.BODY_ACCENT}>{t('common:userMenu.askQuestion')}</Text>
        </a>
    );
};
