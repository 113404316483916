import React, { FC, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthStatus } from 'state/auth/reducer';
import { authRoleSelector, authStatusSelector } from 'state/auth/selectors';

import { RoutePath } from 'RoutePath';

import { UserRole } from 'types';

/**
 * BuyerWithLocationRoute
 * Allows only:
 *  - guest users with saved address
 *  - logged in users with role UserRole.CUSTOMER and saved address
 */
export const BuyerWithLocationRoute: FC<RouteProps> = props => {
    const status = useSelector(authStatusSelector);
    const role = useSelector(authRoleSelector);
    if ((status === AuthStatus.LOGGED_IN && role === UserRole.CUSTOMER) || status === AuthStatus.GUEST) {
        return <Route {...props} />;
    }
    return <Redirect to={RoutePath.VENDOR_DASHBOARD} />;
};

// SSR
export const shouldRedirectFromFeedHook = () => {
    const status = useSelector(authStatusSelector);
    const role = useSelector(authRoleSelector);
    useEffect(() => {
        if (!((status === AuthStatus.LOGGED_IN && role === UserRole.CUSTOMER) || status === AuthStatus.GUEST)) {
            window.location.assign(RoutePath.VENDOR_DASHBOARD);
        }
    }, []);
};
