import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';

import { getMarkerItem, renderFeedMap, renderMarker } from 'core/maps/maps';
import { useAction } from 'core/store/store';

import { feedHoveredStoreIdSelector, feedSelectedStoreIdSelector } from 'state/feed/selectors';
import { makeFeedSetHoveredStoreIdAction, makeFeedSetSelectedStoreIdAction } from 'state/feed/actions';

import { ShopDialogInfo } from 'pages/Feed/components/ShopDialogInfo/ShopDialogInfo';

import { StoreCategory } from 'types';

import { MapControls } from './MapControls';

export interface GeoMapMarker {
    storeId: string;
    active: boolean;
    geoCoordinates: {
        latitude: number;
        longitude: number;
    };
    openForOrders: boolean;
    hasActiveSubscription: boolean;
    storeCategory: StoreCategory;
}

export interface GeoMapBounds {
    latMin: number;
    lonMin: number;
    lonMax: number;
    latMax: number;
}

export interface GeoMapWithMarkersProps {
    lat: number;
    lon: number;
    userCoordinates: { lat: number; lon: number };
    onBoundsChanged: (props: GeoMapBounds) => void;
    // Optional:
    initialBounds: InitialBounds;
    markers?: GeoMapMarker[];
    preselectedStoreId?: string;
}

export interface InitialBounds {
    sw: { lat: number; lon: number };
    ne: { lat: number; lon: number };
}

const useStyles = makeStyles(theme => ({
    map: {
        width: '100%',
        height: '100%',
    },
    mapControls: {
        position: 'absolute',
        right: theme.spacing(1),
        bottom: theme.spacing(6),
    },
}));

export const setInitialBounds = (
    initialBounds: InitialBounds,
    map: google.maps.Map,
    lat: number,
    lon: number,
): void => {
    if (initialBounds) {
        // Ensure that map center is (lat, lon)
        const dx = Math.max(Math.abs(initialBounds.ne.lon - lon), Math.abs(initialBounds.sw.lon - lon));
        const dy = Math.max(Math.abs(initialBounds.ne.lat - lat), Math.abs(initialBounds.sw.lat - lat));

        const defaultShift = 0.5;

        if (dx === Infinity || dy === Infinity) {
            const bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(lat - defaultShift, lon - defaultShift),
                new google.maps.LatLng(lat + defaultShift, lon + defaultShift),
            );
            map.fitBounds(bounds);
        } else {
            const bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(lat - dy, lon - dx),
                new google.maps.LatLng(lat + dy, lon + dx),
            );
            map.fitBounds(bounds);
        }
        const zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
            if (map.getZoom() < 9) {
                map.setZoom(9);
            }
        });
        setTimeout(function () {
            google.maps.event.removeListener(zoomChangeBoundsListener);
        }, 2000);
    }
};

export const GeoMapWithMarkers = (props: GeoMapWithMarkersProps) => {
    const { initialBounds, lat, lon, userCoordinates, onBoundsChanged, markers, preselectedStoreId } = props;
    const classes = useStyles();
    const action = useAction();

    const hoveredStoreId = useSelector(feedHoveredStoreIdSelector);
    const selectedStoreId = useSelector(feedSelectedStoreIdSelector);

    const mapElementRef = useRef<HTMLDivElement>();
    const mapRef = useRef<google.maps.Map>();
    const infoWindow = useRef(new google.maps.InfoWindow());

    const isMapInitializedRef = useRef(false);
    const previousHoveredMarker = useRef(null);
    const previousSelectedMarker = useRef(null);
    const selectedStoreIdRef = useRef(selectedStoreId);
    const [isUserPlaceMarker, setIsUserPlaceMarker] = useState(false);

    const USER_PLACE_ICON = {
        url: `/google-map-icons/user_place.svg`,
        scaledSize: new google.maps.Size(48, 48),
    };

    useEffect(() => {
        selectedStoreIdRef.current = selectedStoreId;
    }, [selectedStoreId, selectedStoreIdRef]);

    const selectedStoreContent = useRef<HTMLElement>();

    useEffect(() => {
        if (mapRef.current) {
            google.maps.event.addListener(mapRef.current, 'click', () => {
                infoWindow.current.close();
                previousSelectedMarker.current = selectedStoreIdRef.current;
                action(makeFeedSetSelectedStoreIdAction, null);
            });
        }
    }, [mapRef.current]);

    useEffect(() => {
        if (isMapInitializedRef.current) {
            const center = mapRef.current.getCenter();
            const currentLat = center.lat();
            const currentLon = center.lng();
            if (currentLat !== lat || currentLon !== lon) {
                mapRef.current.setCenter({ lat, lng: lon });
            }
        } else {
            if (lat === null || lon === null) {
                return;
            }
            isMapInitializedRef.current = true;
            const map = renderFeedMap(mapElementRef.current, lat, lon);
            setInitialBounds(initialBounds, map, lat, lon);
            const boundsChangedHandler = () => {
                const bounds = map.getBounds();
                if (bounds) {
                    const jsonBounds = bounds.toJSON();
                    onBoundsChanged({
                        latMin: jsonBounds.south,
                        lonMin: jsonBounds.west,
                        lonMax: jsonBounds.east,
                        latMax: jsonBounds.north,
                    });
                }
            };
            map.addListener('bounds_changed', debounce(boundsChangedHandler, 300));
            mapRef.current = map;
        }
    }, [lat, lon, onBoundsChanged, initialBounds]);

    useEffect(() => {
        const center = mapRef.current.getCenter();
        const lat = center.lat();
        const lon = center.lng();
        setInitialBounds(initialBounds, mapRef.current, lat, lon);
    }, [initialBounds, mapRef.current]);

    const previousStores = useRef(new Map<string, google.maps.Marker>());

    useEffect(() => {
        if (preselectedStoreId) {
            const preselectedStoreGeoMarker = previousStores.current.get(preselectedStoreId);
            if (preselectedStoreGeoMarker) {
                google.maps.event.trigger(preselectedStoreGeoMarker, 'click');
            }
        }
    }, [preselectedStoreId, previousStores.current]);

    useEffect(() => {
        if (isMapInitializedRef.current) {
            const newStoreIds = new Set<string>();
            markers.forEach(marker => {
                newStoreIds.add(marker.storeId);
                const isHovered = marker.storeId === hoveredStoreId;
                const isSelected = marker.storeId === selectedStoreId;
                const isChanged =
                    hoveredStoreId !== previousHoveredMarker.current ||
                    selectedStoreId !== previousSelectedMarker.current;

                const isPreviousSelected = marker.storeId === previousSelectedMarker.current;
                const isPreviousHovered = marker.storeId === previousHoveredMarker.current;

                const changedMarker = previousStores.current.get(marker.storeId);

                if (
                    !!changedMarker &&
                    !((isHovered || isSelected || isPreviousHovered || isPreviousSelected) && isChanged)
                ) {
                    return;
                }

                const { active, openForOrders, storeCategory, hasActiveSubscription } = marker;

                const hoveredIcon = getMarkerItem(active && openForOrders, storeCategory, hasActiveSubscription, true);
                const icon = getMarkerItem(active && openForOrders, storeCategory, hasActiveSubscription, false);

                if (changedMarker) {
                    changedMarker.setIcon(isHovered || isSelected ? hoveredIcon : icon);
                    changedMarker.setZIndex(isHovered || isSelected ? 1000 : 1);
                } else {
                    const content = document.createElement('div');

                    const geoMarker = renderMarker(
                        marker.geoCoordinates.latitude,
                        marker.geoCoordinates.longitude,
                        mapRef.current,
                    );

                    geoMarker.setIcon(isHovered || isSelected ? hoveredIcon : icon);
                    geoMarker.setZIndex(isHovered || isSelected ? 1000 : 1);

                    geoMarker.addListener('mouseover', () => {
                        action(makeFeedSetHoveredStoreIdAction, marker.storeId);
                    });
                    geoMarker.addListener('mouseout', () => {
                        action(makeFeedSetHoveredStoreIdAction, null);
                    });
                    previousStores.current.set(marker.storeId, geoMarker);

                    geoMarker.addListener('click', () => {
                        previousSelectedMarker.current = selectedStoreIdRef.current;
                        infoWindow.current.close();
                        infoWindow.current.setContent(content);
                        infoWindow.current.open(mapRef.current, geoMarker);
                        selectedStoreContent.current = content;
                        action(makeFeedSetSelectedStoreIdAction, marker.storeId);
                    });
                }
            });
            previousHoveredMarker.current = hoveredStoreId;
            previousStores.current.forEach((marker, storeId) => {
                if (!newStoreIds.has(storeId)) {
                    if (storeId === selectedStoreId) {
                        selectedStoreContent.current = null;
                        previousSelectedMarker.current = storeId;
                        action(makeFeedSetSelectedStoreIdAction, null);
                    }
                    marker.setMap(null);
                    previousStores.current.delete(storeId);
                }
            });
        }
    }, [
        markers,
        hoveredStoreId,
        selectedStoreId,
        previousSelectedMarker,
        previousHoveredMarker.current,
        previousHoveredMarker,
        previousSelectedMarker.current,
        action,
    ]);

    useEffect(() => {
        if (isMapInitializedRef.current && !isUserPlaceMarker) {
            const userMarker = renderMarker(userCoordinates.lat, userCoordinates.lon, mapRef.current);
            userMarker.setIcon(USER_PLACE_ICON);
            userMarker.setZIndex(1000);
            setIsUserPlaceMarker(true);
        }
    }, [userCoordinates, isUserPlaceMarker]);

    return (
        <>
            <div ref={r => (mapElementRef.current = r)} className={classes.map} />
            <div className={classes.mapControls}>
                <MapControls
                    onZoomIn={() => {
                        if (mapRef.current) {
                            const zoom = mapRef.current.getZoom();
                            mapRef.current.setZoom(zoom + 1);
                        }
                    }}
                    onZoomOut={() => {
                        if (mapRef.current) {
                            const zoom = mapRef.current.getZoom();
                            mapRef.current.setZoom(zoom - 1);
                        }
                    }}
                    onDetectLocation={() =>
                        mapRef.current.setCenter({ lng: userCoordinates.lon, lat: userCoordinates.lat })
                    }
                />
            </div>
            {selectedStoreId &&
                !!selectedStoreContent?.current &&
                createPortal(<ShopDialogInfo storeId={selectedStoreId} />, selectedStoreContent.current)}
        </>
    );
};
