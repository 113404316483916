import React from 'react';
import dynamic from 'next/dynamic';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Feed } from 'pages/Feed/Feed';

import { shouldRedirectFromFeedHook } from 'components/BuyerWithLocationRoute/BuyerWithLocationRoute';
import { ShopShortInfoProps } from '../../components/ShopShortInfo/ShopShortInfo';
import { BannerCarouselItemDto, StoreCategory } from '../../types';
import { DynamicCategories } from '../../components/ShopCategoryFilter/ShopCategoryFilter';
import { CheckoutCartDialog } from 'pages/Checkout/CheckoutCartDialog';

const UserMenuContainer = dynamic<any>(() => import('MenuContainer').then(mod => mod.MenuContainer), {
    ssr: false,
});

export const FeedFacade = ({
    coordinates,
    shopCarouselItemsInitial,
    infiniteShopListInitial,
    shopListNextStartInitial,
    shopListFirstTimeFetched,
    bannerItems,
    categories,
    preselectedShopInfo,
    preselectedTag,
    preselectedCategory,
    isDataLoaded,
    region,
    language,
}: {
    coordinates?: {
        lat: number;
        lon: number;
    };
    preselectedShopInfo?: {
        lat: number;
        lon: number;
        storeId: string;
    };
    preselectedTag?: string;
    preselectedCategory?: StoreCategory;
    shopCarouselItemsInitial?: ShopShortInfoProps[];
    infiniteShopListInitial?: ShopShortInfoProps[];
    shopListNextStartInitial?: string;
    shopListFirstTimeFetched?: boolean;
    bannerItems?: BannerCarouselItemDto[];
    categories?: DynamicCategories[];
    isDataLoaded: boolean;
    region: string;
    language: string;
}) => {
    shouldRedirectFromFeedHook();

    return (
        <ErrorBoundary>
            <CheckoutCartDialog />
            <UserMenuContainer isSsr />
            <Feed
                coordinates={coordinates}
                shopCarouselItemsInitial={shopCarouselItemsInitial}
                infiniteShopListInitial={infiniteShopListInitial}
                shopListNextStartInitial={shopListNextStartInitial}
                shopListFirstTimeFetched={shopListFirstTimeFetched}
                bannerItems={bannerItems}
                categories={categories}
                preselectedShopInfo={preselectedShopInfo}
                preselectedTag={preselectedTag}
                preselectedCategory={preselectedCategory}
                isDataLoaded={isDataLoaded}
                region={region}
                language={language}
            />
        </ErrorBoundary>
    );
};
