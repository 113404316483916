import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core';
import i18n from '../../../../../i18n';

export interface InfiniteScrollWithLoadMoreButtonProps {
    fetchMore: Function;
    hasMore: boolean;
    loader: ReactNode;
    children: ReactNode[];
    className?: string;
}

const useStyles = makeStyles(theme => ({
    loadMoreButtonContainer: {
        display: 'block !important',
        textAlign: 'center',
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.up(640)]: {
            paddingBottom: theme.spacing(5),
        },
    },
}));

export const InfiniteScrollWithLoadMoreButton = (props: InfiniteScrollWithLoadMoreButtonProps) => {
    const { children, fetchMore } = props;

    const classes = useStyles();
    const { t } = i18n.useTranslation();

    const [isFetching, setIsFetching] = useState(false);

    const inheritedStyles = props.className || '';

    useEffect(() => {
        setIsFetching(false);
    }, [children.length]);

    const loadMore = useCallback(() => {
        setIsFetching(true);
        fetchMore();
    }, [setIsFetching, fetchMore]);

    return (
        <>
            <div className={inheritedStyles}>{children}</div>
            {props.hasMore && (
                <div className={classNames(inheritedStyles, classes.loadMoreButtonContainer)}>
                    {isFetching && props.loader}
                    {!isFetching && (
                        <LargeButton color={LargeButtonColor.SUCCESS} onClick={loadMore}>
                            {t('feed:feed.loadMore')}
                        </LargeButton>
                    )}
                </div>
            )}
        </>
    );
};
