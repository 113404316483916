import React, { FC, Fragment, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Hidden, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { checkoutTotalPriceSelector } from 'state/checkout/selectors';
import { makeAppInitAction } from 'state/app/actions';

import { Text, TextTypography } from 'components/Text/Text';

import { RoutePath } from 'RoutePath';
import { useAction } from 'core/store/store';

import { ReactComponent as LogoIcon } from 'assets/logo-icon.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/about/burger.svg';
import { ReactComponent as CartIcon } from 'assets/icons/additional/cart-icon--16.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/main-24/search-o--24.svg';
import { ReactComponent as ArrowMap } from 'assets/icons/main-16/send--16.svg';

import { Icon } from 'components/Icon/Icon';
import i18n from '../../../i18n';
import { makeCheckoutOpenCartAction } from 'state/checkout/actions';
import { feedAddressSelector } from '../../state/feed/selectors';
import { makeFeedLocationOpenedAction } from '../../state/feed/actions';
import { formatCurrency } from '../../core/utils/utils';
import browserCookies from 'browser-cookies';
import { SupportedCountryCodes } from '../../types';

export interface FeedHeaderProps {
    openUserMenu: () => void;
    onSearchClick?: () => void;
    onCreateStoreClick?: () => void;
    onMapClick?: () => void;
    styles?: CSSProperties;
    isFullIcon?: boolean;
    isFromSsr?: boolean;
    hideButtons?: boolean;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 5),
        height: theme.spacing(10),
        width: '100%',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.5,
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        [theme.breakpoints.down(641)]: {
            padding: theme.spacing(3, 3),
        },
        [theme.breakpoints.down(480)]: {
            height: theme.spacing(7),
            padding: theme.spacing(1, 2),
        },
    },
    logo: {
        display: 'flex',
        height: theme.spacing(4),
        marginRight: theme.spacing(1.5),
        '&:hover': {
            '-webkit-transform': 'scale(1.2)',
            '-ms-transform': 'scale(1.2)',
            transform: 'scale(1.2)',
        },
    },
    textRadius: {
        backgroundColor: '#75C345',
        color: 'white',
        maxWidth: 124,
        width: '100%',
        height: theme.spacing(5),
        position: 'relative',
        cursor: 'pointer',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'left',
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        background: 'rgba(15, 24, 37, 0.05)',
        border: '1px solid rgba(15, 24, 37, 0.05)',
        borderRadius: theme.spacing(12.5),
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down(640)]: {
            maxWidth: 40,
        },
    },
    buttonRadius: {
        minWidth: theme.spacing(5),
        minHeight: theme.spacing(5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        borderRadius: theme.spacing(12.5),
        background: 'rgba(15, 24, 37, 0.05)',
    },
    textContainer: {
        minWidth: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -5,
        maxWidth: '100%',
        height: 'inherit',
    },
    cartIconContainer: {
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        margin: theme.spacing(0, 2),
        borderRadius: '100%',
        color: theme.palette.fixedPrimary.primary,
        '&:hover': {
            background: 'rgba(15, 24, 37, 0.05)',
        },
    },
    cartIconPopover: {
        marginTop: 9,
        backgroundColor: theme.palette.fixedPrimary.primary,
        padding: '8px 16px',
        borderRadius: 6,
        color: '#FFFFFF',
    },
    cartIconArrow: {
        '&::before': {
            backgroundColor: theme.palette.fixedPrimary.primary,
        },
    },
    cartIconPopoverActive: {
        color: '#fff',
        backgroundColor: '#FF7171',
        '&:hover': {
            background: '#FF7171',
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto',
        maxWidth: '100%',
        display: 'flex',
        height: 'inherit',
        alignItems: 'center',
        [theme.breakpoints.down(640)]: {
            display: 'none',
        },
    },
    iconBurger: {
        display: 'flex',
        maxHeight: theme.spacing(1.5),
        marginLeft: theme.spacing(5),
        cursor: 'pointer',
        zIndex: 10,
        [theme.breakpoints.down(641)]: {
            display: 'none',
        },
    },
    iconSearch: {
        transform: 'scale(0.6, 0.6)',
        marginRight: 5,
        // color: '#243143',
        [theme.breakpoints.between(638, 1670)]: {
            marginRight: 2,
        },
        [theme.breakpoints.between(321, 336)]: {
            marginRight: 1,
        },
        [theme.breakpoints.down(320)]: {
            marginRight: 0,
        },
    },
    menuHeader: {
        display: 'flex',
        listStyle: 'none',
        margin: '0 auto',
        whiteSpace: 'nowrap',
    },
    menuItem: {
        marginRight: theme.spacing(4),
        color: '#243143',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '&:last-child': {
            marginRight: 0,
        },
        [theme.breakpoints.down(961)]: {
            marginRight: theme.spacing(3),
        },
    },
    basket: {
        display: 'flex',
    },
    button: {
        minWidth: 193,
        marginLeft: theme.spacing(2),
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 2),
        backgroundColor: '#75C345',
        [theme.breakpoints.down(640)]: {
            display: 'none',
        },
        [theme.breakpoints.up(480)]: {
            width: theme.spacing(24.2),
            height: theme.spacing(5),
            border: '1px solid rgba(15, 24, 37, 0.1)',
            borderRadius: theme.spacing(3),
        },
    },
    textButton: {
        color: '#FFFFFF',
    },
    buttonIcon: {
        display: 'flex',
        marginTop: 5,
        marginLeft: 10,
    },
    mapContainer: {
        display: 'flex',
        width: '100%',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(2),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down(960)]: {
            marginLeft: theme.spacing(3),
        },
        [theme.breakpoints.up(1280)]: {
            display: 'none',
        },
    },
    mapState: {
        width: '100%',
        minHeight: theme.spacing(5),
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: theme.spacing(12.5),
        background: 'rgba(15, 24, 37, 0.05)',
    },
    mapIcon: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
}));

const isServer = typeof window === 'undefined';

export const FeedHeader: FC<FeedHeaderProps> = props => {
    const {
        openUserMenu,
        onSearchClick,
        styles,
        isFullIcon = false,
        isFromSsr = false,
    } = props;

    const classes = useStyles();
    const history = useHistory();
    const action = useAction();
    const {
        t,
        i18n: { language },
    } = i18n.useTranslation();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up(768));

    useEffect(() => {
        action(makeAppInitAction); //for SSR
    }, []);

    const cartItemsPrice = useSelector(checkoutTotalPriceSelector);

    const onLogoClick = useCallback(() => {
        window.location.assign(RoutePath.FEED);
    }, [isFromSsr, history]);

    const onCartClick = useCallback(() => {
        if (cartItemsPrice && action) {
            action(makeCheckoutOpenCartAction);
        }
    }, [cartItemsPrice, action]);

    const handleInfoPagesNavigation = useCallback((path: RoutePath) => {
        window.open(path, '_blank');
    }, []);

    const handleOpenMenu = useCallback(() => openUserMenu(), [openUserMenu]);

    const address = useSelector(feedAddressSelector);

    const isoCode = !isServer ? browserCookies?.get('country') : SupportedCountryCodes.RUS;

    return (
        <Box className={classes.container} style={styles} itemScope itemType="http://schema.org/WPHeader">
            <div className={classes.logo} onClick={onLogoClick} itemProp="logo">
                {isFullIcon ? <Logo /> : <LogoIcon />}
            </div>

            <div className={classes.menuHeader}>
                <Hidden only={['xs', 'sm', 'md']}>
                    <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.ABOUT)}>
                        <Text typography={TextTypography.MENU}>{t('common:menu.aboutUs')}</Text>
                    </div>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                    <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.SELLERS)}>
                        <Text typography={TextTypography.MENU}>{t('common:menu.sellers')}</Text>
                    </div>
                </Hidden>
                <Hidden only={['xs', 'sm', 'md']}>
                    <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.BUYERS)}>
                        <Text typography={TextTypography.MENU}>{t('common:menu.buyers')}</Text>
                    </div>
                </Hidden>
                {isoCode === SupportedCountryCodes.RUS && (
                    <Hidden only={['xs', 'sm', 'md']}>
                        <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.DELIVERY)}>
                            <Text typography={TextTypography.MENU}>{t('common:menu.delivery')}</Text>
                        </div>
                    </Hidden>
                )}
                <Hidden only={['xs', 'sm', 'md']}>
                    <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.PAYMENTS)}>
                        <Text typography={TextTypography.MENU}>{t('common:menu.payment')}</Text>
                    </div>
                </Hidden>
                {language === 'ru' && (
                    <Hidden only={['xs', 'sm', 'md']}>
                        <div className={classes.menuItem} onClick={() => handleInfoPagesNavigation(RoutePath.BLOG)}>
                            <Text typography={TextTypography.MENU}>{t('common:menu.blog')}</Text>
                        </div>
                    </Hidden>
                )}
            </div>

            <div className={classes.mapContainer}>
                <div
                    className={classes.mapState}
                    onClick={() => {
                        action(makeFeedLocationOpenedAction);
                    }}
                >
                    <Fragment>
                        <Icon className={classes.mapIcon} component={ArrowMap} size={16} svgSize={16} />
                        <Text shouldCollapseText typography={TextTypography.MENU}>
                            {address}
                        </Text>
                    </Fragment>
                </div>
            </div>

            <div className={classes.textRadius} onClick={onSearchClick}>
                <div className={classes.textContainer}>
                    <Icon component={SearchIcon} className={classes.iconSearch} color="#fff" />
                    <Text className={classes.text} typography={TextTypography.MENU} shouldCollapseText>
                        {t('common:menu.search')}
                    </Text>
                </div>
            </div>

            {isLargeScreen && (
                <Hidden>
                    <Tooltip
                        title={
                            cartItemsPrice
                                ? `${t('common:menu.totalAmount')}: ${formatCurrency(cartItemsPrice)}`
                                : `${t('common:menu.empty')}`
                        }
                        arrow
                        classes={{
                            tooltip: classes.cartIconPopover,
                            arrow: classes.cartIconArrow,
                        }}
                    >
                        <div
                            className={classnames(
                                classes.buttonRadius,
                                cartItemsPrice && classes.cartIconPopoverActive,
                            )}
                            onClick={onCartClick}
                        >
                            <Icon
                                color={cartItemsPrice ? '#FFF' : 'rgb(36, 49, 67)'}
                                component={CartIcon}
                                size={16}
                                svgSize={16}
                            />
                        </div>
                    </Tooltip>
                </Hidden>
            )}

            {/*{isLargeScreen && (*/}
            {/*    <Hidden>*/}
            {/*        <ButtonArrow className={classes.button} onClick={onCreateStoreClick}>*/}
            {/*            {t('common:about.connectShop')}*/}
            {/*        </ButtonArrow>*/}
            {/*    </Hidden>*/}
            {/*)}*/}

            <div className={classes.iconBurger} onClick={handleOpenMenu}>
                <Icon color={'#222'} component={MenuIcon} size={32} />
            </div>
        </Box>
    );
};
