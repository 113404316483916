import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon, Hidden } from '@material-ui/core';

import { makeUrl } from 'core/utils/utils';
import { getRequest } from 'core/axios/axios';

import {
    feedAddressSelector,
    feedCoordinatesSelector,
    feedHasGeolocationSucceededSelector,
    feedIsLocationOpenSelector,
} from 'state/feed/selectors';

import { GeoMapMarker, GeoMapWithMarkers } from 'components/GeoMap/GeoMapWithMarkers';
import { Text, TextTypography } from 'components/Text/Text';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { Endpoint } from 'Endpoint';

import { StoreCategory } from 'types';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';
import { ReactComponent as EllipsisIcon } from 'assets/icons/main-24/elipsis-h--24.svg';
import { makeFeedLocationOpenedAction, makeFeedSetSelectedStoreIdAction } from 'state/feed/actions';
import { useAction } from 'core/store/store';
import { CurrentLocation } from 'components/CurrentLocation/CurrentLocation';
import { OrientationError } from 'components/OrientationError/OrientationError';
import { GoogleMapsLoadedObserver } from '../../components/GoogleMapsLoadedObserver/GoogleMapsLoadedObserver';
import { hasLocalStorageItem, LocalStorageKey } from '../../core/storage/storage';

import css from './FeedMap.module.css';
import i18n from '../../../i18n';

export interface FeedMapProps {
    selectedCategory: StoreCategory;
    // Optional
    initialBounds?: { sw: { lat: number; lon: number }; ne: { lat: number; lon: number } };
    preselectedShopInfo?: { lat: number; lon: number; storeId: string };
    preselectedTag?: string;
    isAlreadyPreselectedShop?: boolean;
    preselectedShopStatusChange?: (preselected: boolean) => void;
    onClose?: () => void;
    onOpenMenu?: () => void;
}

const defaultPreselectedStoreBoundShift = 0.1;

export const FeedMap = (props: FeedMapProps) => {
    const {
        initialBounds,
        onClose,
        onOpenMenu,
        selectedCategory,
        preselectedShopInfo,
        preselectedTag,
        isAlreadyPreselectedShop,
        preselectedShopStatusChange,
    } = props;
    const { t } = i18n.useTranslation();

    const { lat, lon } = useSelector(feedCoordinatesSelector);
    const address = useSelector(feedAddressSelector);
    const hasGeolocationSucceeded = useSelector(feedHasGeolocationSucceededSelector);
    const isLocationOpen = useSelector(feedIsLocationOpenSelector);
    const initialCoordinates = { lat, lon };
    const [coordinates, setCoordinates] = useState(initialCoordinates);
    const [bounds, setBounds] = useState(null);
    const action = useAction();
    const [preselectedShopInitialBounds, setPreselectedShopInitialBounds] = useState(null);
    const [calculatedCoordinates, setCalculatedCoordinates] = useState(null);

    const hasAddressChanged = useMemo(() => {
        return hasLocalStorageItem(LocalStorageKey.ADDRESS_CHANGED_BY_USER);
    }, [address]);

    useEffect(() => {
        if (!isAlreadyPreselectedShop && preselectedShopInfo) {
            setPreselectedShopInitialBounds({
                sw: {
                    lat: preselectedShopInfo.lat + defaultPreselectedStoreBoundShift,
                    lon: preselectedShopInfo.lon - defaultPreselectedStoreBoundShift,
                },
                ne: {
                    lat: preselectedShopInfo.lat + defaultPreselectedStoreBoundShift,
                    lon: preselectedShopInfo.lon + defaultPreselectedStoreBoundShift,
                },
            });
            setCalculatedCoordinates(preselectedShopInfo);
        } else {
            setPreselectedShopInitialBounds(initialBounds);
            setCalculatedCoordinates({
                lat: Number(initialBounds ? (initialBounds.sw.lat + initialBounds.ne.lat) / 2 : coordinates.lat),
                lon: Number(initialBounds ? (initialBounds.sw.lon + initialBounds.ne.lon) / 2 : coordinates.lon),
            });
        }
    }, [preselectedShopInfo, setPreselectedShopInitialBounds, coordinates, initialBounds]);

    // Update coordinates only when geolocation has succeeded
    // No other deps needed

    useEffect(() => {
        if (hasGeolocationSucceeded) {
            setCoordinates({ lat, lon });
        }
    }, [hasGeolocationSucceeded]);

    useEffect(() => {
        if (bounds) {
            const { latMin, lonMin, lonMax, latMax } = bounds;
            const url = makeUrl(
                Endpoint.FEED_MAP,
                {},
                {
                    latMin,
                    longMin: lonMin,
                    longMax: lonMax,
                    latMax,
                    category: selectedCategory,
                    tag: preselectedTag,
                    storeId: preselectedShopInfo?.storeId,
                },
            );
            getRequest(url)
                .then((response: { data: GeoMapMarker[] }) => {
                    setMarkers(response.data);
                })
                .catch(console.log);
        }
    }, [bounds, selectedCategory]);

    useEffect(() => {
        if (initialBounds) {
            const { sw, ne } = initialBounds;
            const url = makeUrl(
                Endpoint.FEED_MAP,
                {},
                {
                    latMin: ne.lat,
                    longMin: sw.lon,
                    longMax: ne.lon,
                    latMax: sw.lat,
                    category: selectedCategory,
                    storeId: preselectedShopInfo?.storeId,
                },
            );
            getRequest(url)
                .then((response: { data: GeoMapMarker[] }) => {
                    setMarkers(response.data);
                })
                .catch(console.log);
        }
    }, [initialBounds, selectedCategory]);

    const [markers, setMarkers] = useState([]);

    const [preselectedStore, setPreselectedStore] = useState(null);
    useEffect(() => {
        if (preselectedShopInfo && !isAlreadyPreselectedShop && markers && markers.length != 0) {
            const preselectedStoreDto = markers.find(
                (marker: GeoMapMarker) =>
                    marker.geoCoordinates.latitude === preselectedShopInfo.lat &&
                    marker.geoCoordinates.longitude === preselectedShopInfo.lon &&
                    marker.storeId === preselectedShopInfo.storeId,
            );
            if (preselectedStoreDto) {
                action(makeFeedSetSelectedStoreIdAction, preselectedStoreDto.storeId);
                setPreselectedStore(preselectedStoreDto);
                if (preselectedShopStatusChange) {
                    preselectedShopStatusChange(true);
                }
            }
        }
    }, [markers, isAlreadyPreselectedShop, preselectedShopInfo?.lat, setPreselectedStore, preselectedShopStatusChange]);

    return (
        <ErrorBoundary>
            <OrientationError>
                <div className={css.container}>
                    {isLocationOpen && (
                        <div className={css.location}>
                            <CurrentLocation mapMode={true} />
                        </div>
                    )}
                    <div className={css.mapContainer}>
                        <div
                            className={css.addressHeader}
                            onClick={() => {
                                action(makeFeedLocationOpenedAction);
                            }}
                        >
                            <div>
                                <Hidden lgUp>
                                    <div
                                        className={css.iconHalo}
                                        onClick={event => {
                                            onClose();
                                            event.stopPropagation();
                                        }}
                                    >
                                        <SvgIcon className={css.backIcon} component={BackIcon} viewBox="0 0 16 16" />
                                    </div>
                                </Hidden>
                            </div>
                            <div className={css.textHeader}>
                                {hasAddressChanged ? (
                                    <Fragment>
                                        <div>
                                            <Text typography={TextTypography.CAPTION}>{t('feed:feed.iAmHere')}</Text>
                                        </div>
                                        <div>
                                            <Text shouldCollapseText typography={TextTypography.BODY_ACCENT}>
                                                {address}
                                            </Text>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Text typography={TextTypography.CAPTION}>{t('feed:feed.clarifyAddress')}</Text>
                                )}
                            </div>
                            <div>
                                <Hidden lgUp>
                                    <div
                                        className={css.iconHalo}
                                        onClick={event => {
                                            onOpenMenu();
                                            event.stopPropagation();
                                        }}
                                    >
                                        <SvgIcon className={css.icon} component={EllipsisIcon} viewBox="0 0 24 24" />
                                    </div>
                                </Hidden>
                            </div>
                        </div>
                        {calculatedCoordinates && (
                            <GoogleMapsLoadedObserver>
                                <GeoMapWithMarkers
                                    initialBounds={preselectedShopInitialBounds}
                                    lat={calculatedCoordinates.lat}
                                    lon={calculatedCoordinates.lon}
                                    onBoundsChanged={setBounds}
                                    markers={markers}
                                    preselectedStoreId={preselectedStore?.storeId}
                                    userCoordinates={initialCoordinates}
                                />
                            </GoogleMapsLoadedObserver>
                        )}
                    </div>
                </div>
            </OrientationError>
        </ErrorBoundary>
    );
};
