import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { useAction, useActionDispatchOnMount } from 'core/store/store';
import { makeUrl } from 'core/utils/utils';

import { makeStoreFetchByIdRequestAction } from 'state/store/actions';
import { makeStoreByIdSelector } from 'state/store/selectors';

import { Distance } from 'components/Distance/Distance';
import { Dot } from 'components/Dot/Dot';
import { DeliveryInfo } from 'components/DeliveryInfo/DeliveryInfo';
import { Loader } from 'components/Loader/Loader';
import { TagList } from 'components/TagList/TagList';
import { Text, TextTypography } from 'components/Text/Text';

import { RoutePath } from 'RoutePath';
import { makeFeedSetSelectedStoreIdAction } from 'state/feed/actions';

import css from './ShopDialogInfo.module.css';

export interface ShopDialogInfoProps {
    storeId: string;
}

export const ShopDialogInfo = (props: ShopDialogInfoProps) => {
    const { storeId } = props;
    const action = useAction();
    useActionDispatchOnMount(true, makeStoreFetchByIdRequestAction, storeId);
    const store = useSelector(makeStoreByIdSelector(storeId));
    const imageId = store?.image?.imageId;

    const handleClick = useCallback(() => {
        window.open(makeUrl(RoutePath.STORE, { storeSlug: store?.customUrl }), '_blank');
        action(makeFeedSetSelectedStoreIdAction, null);
    }, [action, store]);

    return (
        <div className={css.background}>
            {store ? (
                <div onClick={handleClick}>
                    {imageId && (
                        <img className={css.photo} src={`/api/images/${imageId}`} alt={imageId} key={imageId} />
                    )}
                    <Box p={2}>
                        <Box mb={1}>
                            <div className={css.header}>
                                <Text typography={TextTypography.PARA_ACCENT}>{store?.name}</Text>
                            </div>
                        </Box>
                        <Box mb={1.5}>
                            <Text typography={TextTypography.PARA}>{store?.address.formattedAddress}</Text>
                        </Box>
                        <Box mb={2} className={css.delivery}>
                            <Text typography={TextTypography.PARA}>
                                <Distance meters={store?.distance} />
                                {store?.active && (
                                    <Fragment>
                                        <Dot />
                                        <DeliveryInfo deliveries={store?.storeDeliveries} />
                                    </Fragment>
                                )}
                            </Text>
                        </Box>
                    </Box>
                </div>
            ) : (
                <div className={css.loader}>
                    <Loader />
                </div>
            )}
        </div>
    );
};
