import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { StoreCategory } from 'types';

import { ShopCategoryElement } from './ShopCategoryElement';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as ForwardIcon } from 'assets/icons/main-16/chevron-right--16.svg';
import { ReactComponent as BackwardIcon } from 'assets/icons/main-16/chevron-left--16.svg';
import Hidden from '@material-ui/core/Hidden';

export interface ShopCategoryFilterProps {
    selectedCategory: StoreCategory;
    dynamicCategories: DynamicCategories[];
    onSelect: (category: StoreCategory) => void;
}

export interface DynamicCategories {
    numberOfStores: number;
    storeCategory: StoreCategory;
}

const useStyles = makeStyles(theme => ({
    carousel: {
        display: 'flex',
        willChange: 'transform',
        transition: '0.2s',
        [theme.breakpoints.up(640)]: {
            margin: '0 40px',
        },
    },
    arrows: {
        [theme.breakpoints.down(640)]: {
            display: 'none',
        },
    },
    categoryElement: {
        flex: 0,
        flexBasis: 'auto',
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
        '&:first-child': {
            paddingLeft: theme.spacing(0),
        },
        '&:last-child': {
            paddingLeft: theme.spacing(0),
        },
        [theme.breakpoints.up(1060)]: {
            margin: '0 auto',
        },
    },
    wrapper: {
        position: 'relative',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    outerWrapper: {
        position: 'relative',
    },
    rightArrow: {
        position: 'absolute',
        right: 0,
        height: 100,
        width: 88,
        zIndex: 100,
        cursor: 'pointer',
        background: 'linear-gradient(90deg, rgba(250, 250, 250, 0.2) 0, rgba(250, 250, 250, 1) 60px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 10,
        paddingLeft: 48,
    },
    leftArrow: {
        position: 'absolute',
        left: 0,
        height: 100,
        width: 88,
        zIndex: 100,
        cursor: 'pointer',
        background: 'linear-gradient(270deg, rgba(250, 250, 250, 0.2) 0, rgba(250, 250, 250, 1) 60px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 10,
        paddingRight: 48,
    },
}));

const getCanHandleForward = (carouselSlide: number, current?: Element) => {
    if (current && current.children.length) {
        const containerWidth = Array.from(current.children).reduce((acc: number, child: Element) => {
            return acc + child.getBoundingClientRect().width;
        }, 0);
        return containerWidth > current.getBoundingClientRect().width * (carouselSlide + 1);
    }
    return false;
};

export const ShopCategoryFilter: FC<ShopCategoryFilterProps> = ({ selectedCategory, onSelect, dynamicCategories }) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    const sliderWidth = carouselRef?.current?.getBoundingClientRect().width - 80;

    const [canHandleForward, setCanHandleForward] = useState(false);
    const [carouselSlide, setCarouselSlide] = useState(0);

    const canHandleBackward = carouselSlide > 0;

    const handleForward = useCallback(() => {
        if (canHandleForward) {
            setCarouselSlide(carouselSlide + 1);
        }
    }, [setCarouselSlide, carouselSlide, dynamicCategories, canHandleForward]);

    const handleBackward = useCallback(() => {
        if (canHandleBackward) {
            setCarouselSlide(carouselSlide - 1);
        }
    }, [setCarouselSlide, carouselSlide, dynamicCategories]);

    useEffect(() => {
        setCanHandleForward(getCanHandleForward(carouselSlide, carouselRef.current));
    }, [setCanHandleForward, carouselSlide, carouselRef, carouselRef.current, dynamicCategories]);

    return (
        <div className={classes.outerWrapper}>
            <div className={classes.arrows}>
                <Hidden xsUp={!canHandleBackward && !canHandleForward} implementation="css">
                    <div
                        className={classes.rightArrow}
                        style={{
                            opacity: canHandleForward ? 1 : 0.2,
                            width: canHandleForward ? 88 : 40,
                            paddingLeft: canHandleForward ? 48 : 0,
                        }}
                        onClick={handleForward}
                    >
                        <SvgIcon component={ForwardIcon} viewBox="0 0 16 16" />
                    </div>
                    <div
                        className={classes.leftArrow}
                        style={{
                            opacity: canHandleBackward ? 1 : 0.2,
                            width: canHandleBackward ? 88 : 40,
                            paddingRight: canHandleBackward ? 48 : 0,
                        }}
                        onClick={handleBackward}
                    >
                        <SvgIcon component={BackwardIcon} viewBox="0 0 16 16" />
                    </div>
                </Hidden>
            </div>
            <div className={classes.wrapper}>
                <div
                    ref={carouselRef}
                    className={classes.carousel}
                    style={{ transform: `translateX(${carouselSlide * -1 * sliderWidth}px)` }}
                >
                    {dynamicCategories
                        .filter(category => category.storeCategory !== StoreCategory.OTHER)
                        .sort((a, b) => b.numberOfStores - a.numberOfStores)
                        .map(category => (
                            <div key={category.storeCategory} className={classes.categoryElement}>
                                <ShopCategoryElement
                                    category={category.storeCategory}
                                    hasStores={!!category.numberOfStores}
                                    isSelected={selectedCategory === category.storeCategory}
                                    onClick={() => onSelect(category.storeCategory)}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
