import React, { useCallback } from 'react';
import { Box, Grid } from '@material-ui/core';
import AspectRatio from 'react-aspect-ratio';
import { Text, TextTypography } from '../Text/Text';
import { makeStyles } from '@material-ui/core/styles';
import { RoutePath } from 'RoutePath';
import { makeUrl } from 'core/utils/utils';
import { Distance } from '../Distance/Distance';
import { Dot } from '../Dot/Dot';
import { StoreCategory } from 'types';

import DefaultFeedItemImg from 'images/DefaultFeedItemImg.jpg';
import DefaultFeedProductsImage from 'images/DefaultFeedProductsImage.jpg';
import DefaultFeedFlowersImage from 'images/DefaultFeedFlowersImage.jpg';
import DefaultFeedBeautyImage from 'images/DefaultFeedFlowersImage.jpg';

import { useAction } from 'core/store/store';
import { makeFeedSetHoveredStoreIdAction } from 'state/feed/actions';
import { useSelector } from 'react-redux';
import { feedHoveredStoreIdSelector, feedSelectedStoreIdSelector } from 'state/feed/selectors';
import { Icon } from '../Icon/Icon';
import { ReactComponent as SaleIcon } from '../../assets/icons/web-16/store-sale-16.svg';

export interface ShopShortInfoProps {
    storeId: string;
    imageId: string;
    coverImageId: string;
    name: string;
    categories: string;
    customUrl: string;
    distance: number;
    deliveryText: string;
    storeCategory: StoreCategory;
    geoCoordinates?: {
        latitude: number;
        longitude: number;
    };
    // Optional
    className?: string;
    sellingDescription?: string;
    slogan?: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
    },
    productCardPhoto: {
        display: 'block',
        // padding: 4,
        borderRadius: 20,
        flexBasis: theme.spacing(13),
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    title: {
        width: '100%',
        marginTop: theme.spacing(2),
        paddingRight: 4,
        paddingLeft: 4,
        [theme.breakpoints.between(959, 1919)]: {
            fontSize: 18,
        },
        [theme.breakpoints.down(768)]: {
            fontSize: 18,
        },
    },
    text: {
        width: '100%',
        paddingRight: 4,
        paddingLeft: 4,
        marginBottom: 4,
        '&:last-child': {
            marginBottom: 0,
        },
        [theme.breakpoints.between(959, 1919)]: {
            fontSize: 14,
        },
        [theme.breakpoints.down(768)]: {
            fontSize: 14,
        },
    },
    sellContainer: {
        display: 'flex',
        '& svg': {
            marginTop: 4,
            marginRight: 8,
        },
    },
    sellText: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

const defaultIcon = (category: StoreCategory): string => {
    switch (category) {
        case StoreCategory.PRODUCTS:
        case StoreCategory.RESTAURANTS:
            return DefaultFeedProductsImage;
        case StoreCategory.FLOWERS:
            return DefaultFeedFlowersImage;
        case StoreCategory.BEAUTY_HEALTH:
            return DefaultFeedBeautyImage;
        default:
            return DefaultFeedItemImg;
    }
};

const IMAGE_SIZES = [720];

export const ShopShortInfo = (props: ShopShortInfoProps) => {
    const {
        storeId,
        imageId,
        name,
        customUrl,
        categories,
        distance,
        deliveryText,
        className,
        coverImageId,
        storeCategory,
        sellingDescription,
        slogan,
    } = props;
    const classes = useStyles();
    const isHovered = useSelector(feedHoveredStoreIdSelector) === storeId;
    const isSelected = useSelector(feedSelectedStoreIdSelector) === storeId;
    const hoverStyle = {
        border: '4px solid rgba(36, 49, 67, 0.2)',
        boxShadow: '0px 10px 25px rgba(36, 49, 67, 0.1)',
        width: '100%',
        padding: 0,
    };
    const action = useAction();
    const handleHover = useCallback(
        (hoveredStoreId: string) => {
            action(makeFeedSetHoveredStoreIdAction, hoveredStoreId);
        },
        [action],
    );

    const shopImageId = imageId || coverImageId;

    return (
        <Box
            className={className}
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(makeUrl(RoutePath.STORE, { storeSlug: customUrl }), '_blank')}
            onMouseEnter={() => handleHover(storeId)}
            onMouseLeave={() => handleHover(null)}
        >
            <div className={classes.container} itemScope itemType="http://schema.org/Organization">
                <link itemProp="url" href={(makeUrl(RoutePath.STORE, { storeSlug: customUrl }), '_blank')} />
                <Grid item>
                    <AspectRatio ratio={1.6}>
                        {shopImageId ? (
                            <img
                                className={classes.productCardPhoto}
                                style={isHovered || isSelected ? hoverStyle : {}}
                                src={`/api/images/${shopImageId}`}
                                srcSet={IMAGE_SIZES.map(h => `/api/images/${shopImageId}?img_v=${h} ${h}w`).join(', ')}
                                sizes="120px"
                                alt={imageId}
                                key={imageId}
                                itemProp="image"
                            />
                        ) : (
                            <img
                                className={classes.productCardPhoto}
                                style={isHovered || isSelected ? hoverStyle : {}}
                                src={defaultIcon(storeCategory)}
                                alt={imageId}
                                key={imageId}
                            />
                        )}
                    </AspectRatio>
                </Grid>
                <Grid item className={classes.title}>
                    <Text typography={TextTypography.SUBTITLE_ACCENT} shouldCollapseText itemProp="name">
                        {name}
                    </Text>
                </Grid>
                <Grid item className={classes.text}>
                    <Text typography={TextTypography.PARA} itemProp="description">
                        <Distance meters={distance} />
                        <Dot /> {deliveryText}
                    </Text>
                </Grid>

                {sellingDescription && (
                    <Grid item className={classes.text}>
                        <div className={classes.sellContainer}>
                            <Icon component={SaleIcon} size={16} svgSize={16} />
                            <Text className={classes.sellText} typography={TextTypography.PARA_LIGHT}>
                                {sellingDescription}
                            </Text>
                        </div>
                    </Grid>
                )}
            </div>
        </Box>
    );
};
