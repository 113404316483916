import React from 'react';
import { Hidden, makeStyles, SvgIcon } from '@material-ui/core';
import { ReactComponent as ForwardIcon } from 'assets/icons/main-16/arrow-right--16.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/social/facebook--24.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/social/insta--24.svg';
import { RoutePath } from 'RoutePath';
import { Text, TextTypography } from 'components/Text/Text';
import i18n from '../../../../../i18n';
import { WhatsappAskQuestion } from 'components/WhatsappAskQuestion/WhatsappAskQuestion';
import { appConfig } from 'config/app';
import classNames from 'classnames';

import css from './FeedFooter.module.css';
import { SupportedCountryCodes } from 'types';
import browserCookies from 'browser-cookies';

export interface FeedFooterNavigationDef {
    path: RoutePath;
    label: string;
}

const feedFooterNavItems: FeedFooterNavigationDef[] = [
    {
        path: RoutePath.ABOUT,
        label: 'common:footer.aboutUs',
    },
    {
        path: RoutePath.SELLERS,
        label: 'common:footer.forSellers',
    },
    {
        path: RoutePath.BUYERS,
        label: 'common:footer.forCustomers',
    },
    {
        path: RoutePath.DELIVERY,
        label: 'common:footer.deliveryInfo',
    },
    {
        path: RoutePath.PAYMENTS,
        label: 'common:footer.paymentInfo',
    },
    {
        path: RoutePath.BLOG,
        label: 'common:footer.blog',
    },
    {
        path: RoutePath.CONTACTS,
        label: 'common:footer.contacts',
    },
];

const useStyles = makeStyles(theme => ({
    footerContainer: {
        borderTop: `1px solid ${theme.palette.brandPrimary.lightest}`,
        margin: '10px 16px 0px 16px',
        [theme.breakpoints.up(640)]: {
            margin: '10px 40px 0px 40px',
        },
    },
    footerNavContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
    },
    footerNavElement: {
        height: theme.spacing(9),
        display: 'flex',
        alignItems: 'center',
        '&:nth-child(n)': {
            borderBottom: `1px solid ${theme.palette.brandPrimary.lightest}`,
        },
        [theme.breakpoints.down(480)]: {
            flexBasis: '100%',
            '& a': {
                padding: 0,
            },
        },
        [theme.breakpoints.between(480, 769)]: {
            flexBasis: '50%',
            '&:nth-child(2n)': {
                '& a': {
                    borderLeft: `1px solid ${theme.palette.brandPrimary.lightest}`,
                    padding: `0 0 0 ${theme.spacing(3)}px`,
                },
            },
            '&:nth-child(2n+1)': {
                '& a': {
                    padding: `0 ${theme.spacing(3)}px 0 0`,
                },
            },
        },
        [theme.breakpoints.up(769)]: {
            flexBasis: '33.33%',
            '&:nth-child(3n+1)': {
                '& a': {
                    padding: `0 ${theme.spacing(3)}px 0 0`,
                },
            },
            '&:nth-child(3n)': {
                '& a': {
                    padding: `0 0 0 ${theme.spacing(3)}px`,
                },
            },
            '&:nth-child(3n+2), &:nth-child(3n)': {
                '& a': {
                    borderLeft: `1px solid ${theme.palette.brandPrimary.lightest}`,
                },
            },
        },
    },
    footerNavElementBody: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0px ${theme.spacing(3)}px`,
    },
    footerNavElementIcon: {
        fontSize: 12,
    },
    footerInfoWrapper: {
        flex: '0 0 auto',
    },
    footerInfoContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
        backgroundColor: 'rgba(15, 24, 37, 0.025)', //0.5 + opacity = 0.5
        borderTop: `1px solid ${theme.palette.brandPrimary.lightest}`,
        marginTop: '-1px',
        padding: '10px 16px 0px 16px',
        [theme.breakpoints.up(640)]: {
            padding: '10px 40px 0px 40px',
        },
    },

    footerInfoElement: {
        [theme.breakpoints.up(640)]: {
            flexBasis: '25%',
        },
        [theme.breakpoints.down(640)]: {
            flexBasis: '50%',
        },
        '&:last-child': {
            flexBasis: '100%',
        },
        height: theme.spacing(10),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.brandPrimary.lightest}`,
    },
    plainText: {
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    whatsApp: {
        padding: 0,
        '& svg': {
            fontSize: 32,
            '& path': {
                fill: `${theme.palette.fixedPrimary.primary}`,
            },
        },
    },
    socials: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-end',
        '& div': {
            display: 'flex',
        },
        '& a': {
            marginLeft: theme.spacing(3),
            '& svg': {
                fontSize: 32,
                '& path': {
                    fill: `${theme.palette.fixedPrimary.primary}`,
                },
            },
        },
    },
    link: {
        color: 'inherit',
    },
    phoneNumberBlockMobileResolution: {
        alignItems: 'flex-start',
    },
    documentsBlockMobileResolution: {
        alignItems: 'flex-end',
    },
}));

const isServer = typeof window === 'undefined';

export const FeedFooter = () => {
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const isoCode = !isServer ? browserCookies?.get('country') : SupportedCountryCodes.RUS;
    const notTrimmedMarketplacePhoneNumber = isoCode !== SupportedCountryCodes.RUS
        ? appConfig.notTrimmedMarketplacePhoneNumber
        : appConfig.notTrimmedMarketplacePhoneNumberRu;
    const marketplacePhoneNumber = isoCode !== SupportedCountryCodes.RUS
        ? appConfig.marketplacePhoneNumber
        : appConfig.marketplacePhoneNumberRu;

    return (
        <>
            <div className={classes.footerContainer}>
                <div className={classes.footerNavContainer}>
                    {feedFooterNavItems
                        .filter(el => isoCode === SupportedCountryCodes.RUS || el.path !== RoutePath.DELIVERY)
                        .map((navigationItem, i) => (
                            <div key={i} className={classes.footerNavElement}>
                                <a
                                    className={classes.footerNavElementBody}
                                    href={navigationItem.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Text typography={TextTypography.BODY_ACCENT} shouldCollapseText itemProp="name">
                                        {t(navigationItem.label)}
                                    </Text>
                                    <SvgIcon
                                        className={classes.footerNavElementIcon}
                                        component={ForwardIcon}
                                        viewBox="0 0 12 12"
                                    />
                                </a>
                            </div>
                        ))}
                </div>
            </div>
            <div className={classes.footerInfoWrapper}>
                <div className={classes.footerInfoContainer}>
                    <div className={classes.footerInfoElement}>
                        <WhatsappAskQuestion className={classes.whatsApp}
                                             marketplacePhoneNumber={marketplacePhoneNumber} />
                    </div>
                    <Hidden xsDown>
                        <div className={classes.footerInfoElement}>
                            <a href={RoutePath.LEGAL} className={classes.plainText}>
                                <Text typography={TextTypography.BODY_ACCENT}>{t('common:footer.documents')}</Text>
                            </a>
                        </div>
                        <div className={classes.footerInfoElement}>
                            <div className={classes.plainText}>
                                {isoCode !== SupportedCountryCodes.RUS && <Text typography={TextTypography.BODY_ACCENT}>
                                    <a href={`tel:${marketplacePhoneNumber}`} className={classes.link}>
                                        {notTrimmedMarketplacePhoneNumber}
                                    </a>
                                </Text>}
                            </div>
                        </div>
                    </Hidden>
                    {isoCode !== SupportedCountryCodes.RUS && (
                        <div className={classes.footerInfoElement}>
                            <div className={classes.socials}>
                                <div>
                                    <a
                                        href="https://www.facebook.com/profile.php?id=100086871993558"
                                        target="_blank"
                                        itemProp="url"
                                        rel="noopener noreferrer"
                                    >
                                        <div>
                                            <SvgIcon component={FacebookIcon} viewBox="0 0 32 32" />
                                        </div>
                                    </a>
                                    <a
                                        href="https://instagram.com/any.market.uk?igshid=YmMyMTA2M2Y="
                                        target="_blank"
                                        itemProp="url"
                                        rel="noopener noreferrer"
                                    >
                                        <div>
                                            <SvgIcon component={InstagramIcon} viewBox="0 0 32 32" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    <Hidden smUp>
                        <div className={classes.footerInfoElement}>
                            <div className={classNames(classes.plainText, classes.phoneNumberBlockMobileResolution)}>
                                <Text typography={TextTypography.BODY_ACCENT}>
                                    {isoCode !== SupportedCountryCodes.RUS &&<a href={`tel:${marketplacePhoneNumber}`} className={classes.link}>
                                        {notTrimmedMarketplacePhoneNumber}
                                    </a>}
                                </Text>
                            </div>
                        </div>
                        <div className={classes.footerInfoElement}>
                            <a
                                href={RoutePath.LEGAL}
                                className={classNames(classes.plainText, classes.documentsBlockMobileResolution)}
                            >
                                <Text typography={TextTypography.BODY_ACCENT}>{t('common:footer.documents')}</Text>
                            </a>
                        </div>
                    </Hidden>
                    <div className={classes.footerInfoElement}>
                        <div className={classes.plainText}>
                            <Text className={css.copyrightText} typography={TextTypography.BODY_ACCENT}>
                                © {new Date().getFullYear()} any.market
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
