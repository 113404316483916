import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { T } from 'components/T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { Text, TextTypography } from 'components/Text/Text';

import { StoreCategory, StoreCategoryFilter } from 'types';

import BeautyHealthIcon from 'assets/icons/categories/BEAUTY_HEALTH.svg';
import ClothsShoesIcon from 'assets/icons/categories/CLOTHS_SHOES.svg';
import FlowersIcon from 'assets/icons/categories/FLOWERS.svg';
import ForChildrenIcon from 'assets/icons/categories/FOR_CHILDREN.svg';
import ForHomeIcon from 'assets/icons/categories/FOR_HOME.svg';
import PetsIcon from 'assets/icons/categories/PETS.svg';
import ProductsIcon from 'assets/icons/categories/PRODUCTS.svg';
import RestaurantsIcon from 'assets/icons/categories/RESTAURANTS.svg';
import SportsIcon from 'assets/icons/categories/SPORTS.svg';
import BuildingMaterialsIcon from 'assets/icons/categories/BUILDING_MATERIALS.svg';
import Furniture from 'assets/icons/categories/FURNITURE.svg';
import Services from 'assets/icons/categories/SERVICE.svg';
import Education from 'assets/icons/categories/EDUCATION.svg';

export interface ShopCategoryElementProps {
    category: StoreCategoryFilter;
    hasStores: boolean;
    onClick: () => void;
    // Optional:
    isSelected?: boolean;
}

const categoryIcons: Record<StoreCategoryFilter, unknown> = {
    [StoreCategory.PRODUCTS]: ProductsIcon,
    [StoreCategory.RESTAURANTS]: RestaurantsIcon,
    [StoreCategory.FLOWERS]: FlowersIcon,
    [StoreCategory.FOR_HOME]: ForHomeIcon,
    [StoreCategory.FOR_CHILDREN]: ForChildrenIcon,
    [StoreCategory.CLOTHS_SHOES]: ClothsShoesIcon,
    [StoreCategory.PETS]: PetsIcon,
    [StoreCategory.BEAUTY_HEALTH]: BeautyHealthIcon,
    [StoreCategory.SPORTS]: SportsIcon,
    [StoreCategory.BUILDING]: BuildingMaterialsIcon,
    [StoreCategory.FURNITURE]: Furniture,
    [StoreCategory.SERVICES]: Services,
    [StoreCategory.EDUCATION]: Education,
    [StoreCategory.OTHER]: null,
};

const useStyles = makeStyles(theme => ({
    element: {
        width: 80,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down(480)]: {
            width: 'auto',
            marginRight: 16,
            marginBottom: 16,
        },
    },
    icon: {
        zIndex: 1,
    },
    circle: {
        cursor: ({ hasStores }: Partial<ShopCategoryElementProps>) => hasStores && 'pointer',
        backgroundColor: ({ isSelected, category }: Partial<ShopCategoryElementProps>) => {
            return isSelected ? theme.palette.fixedPromoGreen.main : theme.palette.brandPrimary.lightest;
        },
        backgroundImage: ({ category }: Partial<ShopCategoryElementProps>) => `url(${categoryIcons[category]})`,
        opacity: ({ hasStores }: Partial<ShopCategoryElementProps>) => !hasStores && 0.5,
        width: 80,
        height: 80,
        borderRadius: 40,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        '&:hover': {
            backgroundImage: ({ category, hasStores }: Partial<ShopCategoryElementProps>) =>
                hasStores &&
                `url(${categoryIcons[category]}), linear-gradient(180deg, rgba(15, 24, 37, 0) 0%, rgba(15, 24, 37, 0.1) 100%)`,
        },
        [theme.breakpoints.down(480)]: {
            width: 48,
            height: 48,
        },
    },
    hover: {
        width: 80,
        height: 80,
        borderRadius: 40,
        [theme.breakpoints.down(480)]: {
            width: 40,
            height: 40,
        },
    },
    label: {
        display: 'inline-block',
        marginTop: 4,
    },
}));

export const ShopCategoryElement = (props: ShopCategoryElementProps) => {
    const { category, onClick, isSelected, hasStores } = props;
    const classes = useStyles({ isSelected, category, hasStores });
    return (
        <div className={classes.element} onClick={hasStores ? onClick : () => ({})}>
            <div className={classes.circle} />
            <div className={classes.label}>
                <Text typography={TextTypography.CAPTION}>
                    <T enum={TranslatableEnum.STORE_CATEGORY_SHORT}>{category}</T>
                </Text>
            </div>
        </div>
    );
};
