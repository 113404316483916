import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: theme.palette.fixedPrimary.secondary,
        position: 'fixed',
        top: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000000,
    },
    ldsRing: {
        display: 'inline-block',
        position: 'relative',
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        width: 80,
        height: 80,
        '& div': {
            boxSizing: 'border-box',
            display: 'block',
            position: 'absolute',
            width: 64,
            height: 64,
            margin: 8,
            border: '8px solid #243143',
            borderRadius: '50%',
            animation: '$ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
            borderColor: '#243143 transparent transparent transparent',
            '&:nth-child(1)': {
                animationDelay: '-0.45s',
            },
            '&:nth-child(2)': {
                animationDelay: '-0.3s',
            },
            '&:nth-child(3)': {
                animationDelay: '-0.15s',
            },
        },
    },
    '@keyframes ldsRing': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
}));

export const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.ldsRing}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export const FullScreenLoader = () => {
    const classes = useStyles();
    return (
        <div className={classes.background}>
            <Loader />
        </div>
    );
};
