import browserCookies from 'browser-cookies';
import React, { useCallback, useEffect, useRef, useState, FC } from 'react';
import i18n from '../../../i18n';
import { useSelector } from 'react-redux';
import { Hidden, NoSsr, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useAction } from 'core/store/store';
import { getRequest } from 'core/axios/axios';
import { makeUrl } from 'core/utils/utils';

import { makeUserMenuShowAction } from 'state/userMenu/actions';
import { feedCoordinatesSelector } from 'state/feed/selectors';

import { FeedHeader } from 'components/FeedHeader/FeedHeader';
import { Text, TextTypography } from 'components/Text/Text';
import { Icon } from 'components/Icon/Icon';
import { getDeliveryTextMobile } from 'components/DeliveryInfo/utils';
import { DynamicCategories, ShopCategoryFilter } from 'components/ShopCategoryFilter/ShopCategoryFilter';
import { ShopShortInfoProps } from 'components/ShopShortInfo/ShopShortInfo';

import { FeedShops, InfiniteShopList } from 'pages/Feed/components/InfiniteShopList/InfiniteShopList';
import { EmptyFeed } from 'pages/Feed/components/EmptyFeed/EmptyFeed';
import { FeedScreenState } from './types';

import { FeedMap } from './FeedMap';

import { Endpoint } from 'Endpoint';

import { BannerCarouselItemDto, StoreCategory } from 'types';

import { ReactComponent as MapIcon } from 'assets/icons/main-16/map-marker--16.svg';
import { RoutePath } from 'RoutePath';
import { CurrentLocation } from 'components/CurrentLocation/CurrentLocation';
import { FeedFooter } from './components/FeedFooter/FeedFooter';
import { CustomerMenu, CustomerMenuSelectedItem } from 'components/CustomerMenu/CustomerMenu';
import classNames from 'classnames';
import {
    makeFeedLocationOpenedAction,
    makeFeedSetAddressAction,
    makeFeedSetAddressChangedAction,
    makeFeedSetCoordinatesAction,
} from 'state/feed/actions';
import { BannerCarousel } from 'components/BannerCarousel/BannerCarousel';
import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';
import { appConfig } from 'config/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import css from './Feed.module.css';
import { useWindowDimensions } from 'core/hooks/useWindowDimensions';
import { useMounted } from '../../core/hooks/useMounted';

const AddToHomeScreen = dynamic<any>(
    () => import('components/AddToHomeScreen/AddToHomeScreen').then(mod => mod.AddToHomeScreen),
    {
        ssr: false,
    },
);

export const parseFeedStoreData = (data: FeedShops) => {
    return data.storeCards.reduce((acc: ShopShortInfoProps[], store) => {
        const deliveryText = getDeliveryTextMobile(store.storeDelivery);
        acc.push({
            storeId: store.storeId,
            imageId: store.image?.imageId,
            coverImageId: store.coverImage?.imageId,
            name: store.name,
            categories: store.tags.map(el => el.name).join(', '),
            storeCategory: store.storeCategory,
            distance: store.distance,
            customUrl: store.customUrl,
            deliveryText,
            sellingDescription: store.sellingDescription,
            slogan: store.slogan,
        });
        return acc;
    }, []);
};

export interface CalculateInitialBoundsItem {
    distance?: number;
    geoCoordinates?: { latitude: number; longitude: number };
}

const calculateInitialBounds = (storeCards: CalculateInitialBoundsItem[], lat?: number, lon?: number) => {
    if (storeCards.length === 0) {
        if (!lat && !lon) {
            return null;
        }

        return {
            sw: { lat: lat + 0.1, lon: lon - 0.1 },
            ne: { lat: lat - 0.1, lon: lon + 0.1 },
        };
    }

    const storeCoordinates = storeCards.map(c => c.geoCoordinates).filter(Boolean);

    const lats = storeCoordinates.map(c => c?.latitude);
    const lons = storeCoordinates.map(c => c?.longitude);

    const ne = { lat: Math.min(...lats), lon: Math.max(...lons) };
    const sw = { lat: Math.max(...lats), lon: Math.min(...lons) };
    return { sw, ne };
};

interface FeedProps {
    coordinates?: {
        lat: number;
        lon: number;
    };
    preselectedShopInfo?: {
        lat: number;
        lon: number;
        storeId: string;
    };
    preselectedTag?: string;
    preselectedCategory?: StoreCategory;
    shopCarouselItemsInitial?: ShopShortInfoProps[];
    infiniteShopListInitial?: ShopShortInfoProps[];
    shopListNextStartInitial?: string;
    shopListFirstTimeFetched?: boolean;
    bannerItems?: BannerCarouselItemDto[];
    categories?: DynamicCategories[];
    isDataLoaded: boolean;
    region: string;
    language: string;
}

const defaultCategories: DynamicCategories[] = [
    { numberOfStores: 0, storeCategory: StoreCategory.PRODUCTS },
    { numberOfStores: 0, storeCategory: StoreCategory.RESTAURANTS },
    { numberOfStores: 0, storeCategory: StoreCategory.FOR_HOME },
    { numberOfStores: 0, storeCategory: StoreCategory.FLOWERS },
    { numberOfStores: 0, storeCategory: StoreCategory.FOR_CHILDREN },
    { numberOfStores: 0, storeCategory: StoreCategory.CLOTHS_SHOES },
    { numberOfStores: 0, storeCategory: StoreCategory.PETS },
    { numberOfStores: 0, storeCategory: StoreCategory.BEAUTY_HEALTH },
    { numberOfStores: 0, storeCategory: StoreCategory.SPORTS },
    { numberOfStores: 0, storeCategory: StoreCategory.BUILDING },
    { numberOfStores: 0, storeCategory: StoreCategory.FURNITURE },
    { numberOfStores: 0, storeCategory: StoreCategory.SERVICES },
    { numberOfStores: 0, storeCategory: StoreCategory.EDUCATION },
];

export const Feed: FC<FeedProps> = ({
    coordinates,
    shopCarouselItemsInitial = [],
    infiniteShopListInitial = [],
    shopListNextStartInitial = null,
    shopListFirstTimeFetched = false,
    bannerItems = [],
    categories = [],
    preselectedShopInfo = null,
    preselectedTag = null,
    preselectedCategory = null,
    isDataLoaded = false,
    region = '',
    language = '',
}) => {
    const { t } = i18n.useTranslation();
    const theme = useTheme();
    const { lat, lon } = coordinates?.lat ? coordinates : useSelector(feedCoordinatesSelector);

    const isMobileScreen = useMediaQuery(theme.breakpoints.down(1280));

    const feedAlreadyVisited = getLocalStorageItem(LocalStorageKey.FEED_VISITED);

    const [feedScreenState, setFeedScreenState] = useState<FeedScreenState>(FeedScreenState.LIST);
    const action = useAction();

    const [selectedCategory, setSelectedCategory] = useState<StoreCategory>(preselectedCategory);
    const [isAlreadyPreselectedShop, setIsAlreadyPreselectedShop] = useState(false);

    useEffect(() => {
        if (!isAlreadyPreselectedShop && isMobileScreen && preselectedShopInfo) {
            setFeedScreenState(FeedScreenState.MAP);
        }
    }, [isMobileScreen, preselectedShopInfo, setIsAlreadyPreselectedShop, isAlreadyPreselectedShop]);

    // Dynamic categories
    const [isDynamicCategoriesFirstLoad, setIsDynamicCategoriesFirstLoad] = useState(true);
    const [dynamicCategories, setDynamicCategories] = useState<DynamicCategories[]>(
        categories.length > 0 ? categories : defaultCategories,
    );
    useEffect(() => {
        if (isDynamicCategoriesFirstLoad) {
            setIsDynamicCategoriesFirstLoad(false);
            if (dynamicCategories && dynamicCategories.some(c => c.numberOfStores > 0)) {
                return;
            }
        }
        const url = makeUrl(
            Endpoint.FEED_CATEGORIES,
            {},
            {
                latitude: lat,
                longitude: lon,
                tag: preselectedTag || undefined,
            },
        );
        getRequest(url)
            .then((response: { data: DynamicCategories[] }) => {
                return setDynamicCategories(response.data);
            })
            .catch(console.log);
    }, [lat, lon, setDynamicCategories]);

    const [shopCarouselItems, setShopCarouselItems] = useState(shopCarouselItemsInitial);
    const [isShopCarouselItemsFirstLoad, setIsShopCarouselItemsFirstLoad] = useState(true);
    useEffect(() => {
        if (isShopCarouselItemsFirstLoad) {
            setIsShopCarouselItemsFirstLoad(false);
            if (shopCarouselItems && shopCarouselItems.length > 0) {
                return;
            }
        }
        const url = makeUrl(
            Endpoint.FEED_NEW,
            {},
            {
                latitude: lat,
                longitude: lon,
                category: selectedCategory,
                tag: preselectedTag || undefined,
            },
        );
        if (lat && lon) {
            getRequest(url)
                .then((response: { data: FeedShops }) => {
                    return setShopCarouselItems(parseFeedStoreData(response.data));
                })
                .catch(console.log);
        }
    }, [lat, lon, setShopCarouselItems, selectedCategory]);

    const [initialMapBounds, setInitialMapBounds] = useState(calculateInitialBounds(infiniteShopListInitial));
    const infShopWrapper = useRef<HTMLDivElement>();

    //infiniteShopList
    const [isInfiniteShopListLoading, setIsInfiniteShopListLoading] = useState(true);
    const [infiniteShopList, setInfiniteShopList] = useState(infiniteShopListInitial);

    const [start, setStart] = useState(shopListNextStartInitial);
    const [hasMore, setHasMore] = useState(!!shopListNextStartInitial);
    const [isFirstFetch, setIsFirstFetch] = useState(!shopListFirstTimeFetched);
    const fetchMoreData = useCallback(() => {
        const url = makeUrl(
            Endpoint.FEED,
            {},
            {
                latitude: lat,
                longitude: lon,
                start,
                category: selectedCategory,
                tag: preselectedTag || undefined,
            },
        );
        if (lat && lon) {
            const isFirst = isFirstFetch;
            setIsInfiniteShopListLoading(true);
            getRequest(url)
                .then((response: { data: FeedShops }) => {
                    setStart(response.data.nextStart);
                    setHasMore(!!response.data.nextStart);

                    const initialBounds = calculateInitialBounds(response.data.storeCards, lat, lon);
                    setInitialMapBounds(initialBounds);

                    const parsedData = parseFeedStoreData(response.data);
                    return setInfiniteShopList(isFirst ? parsedData : infiniteShopList.concat(parsedData));
                })
                .catch(console.log)
                .then(() => {
                    setIsInfiniteShopListLoading(false);
                    setIsFirstFetch(false);
                });
        }
    }, [infiniteShopList, start, setInfiniteShopList, lat, lon, selectedCategory, isFirstFetch, setInitialMapBounds]);

    useEffect(() => {
        // list of shops is loaded
        if (!isFirstFetch) {
            // not visited feed page & list of shops is empty
            if (!feedAlreadyVisited && !infiniteShopList.length && !(lat || lon)) {
                const countryCode = browserCookies.get('country');
                const defaultLocation = appConfig.defaultLocations[countryCode] ?? appConfig.defaultLocations.RU;
                const { lat, lon, qc_geo, address } = defaultLocation;

                action(makeFeedSetAddressAction, address);
                action(makeFeedSetCoordinatesAction, lat, lon, qc_geo);
                action(makeFeedSetAddressChangedAction);

                setTimeout(() => window.location.reload(), 100);
            }
            // mark the feed page as visited even if the list of shops not empty
            setLocalStorageItem(LocalStorageKey.FEED_VISITED, true);
        }
    }, [infiniteShopList, isFirstFetch, feedAlreadyVisited]);

    const [isInfiniteShopListFirstLoad, setIsInfiniteShopListFirstLoad] = useState(true);
    useEffect(() => {
        if (isInfiniteShopListFirstLoad) {
            setIsInfiniteShopListFirstLoad(false);
            if (infiniteShopList && infiniteShopList.length > 0) {
                return;
            }
        }
        fetchMoreData();
    }, [lat, lon, selectedCategory]);

    const hasFeed = infiniteShopList.length !== 0 || shopCarouselItems.length !== 0;
    const hasShopList = infiniteShopList.length !== 0;

    const onCategorySelect = useCallback(
        (category: StoreCategory) => {
            if (category === selectedCategory) {
                setSelectedCategory(null);
            } else {
                setSelectedCategory(category);
            }
            setIsFirstFetch(true);
            setStart(undefined);
        },
        [selectedCategory],
    );

    const handleOpenUserMenu = useCallback(() => action(makeUserMenuShowAction), [action]);

    const handleCreateStoreClick = useCallback(() => {
        window.location.assign(RoutePath.ONBOARDING_REGISTRATION);
    }, []);

    const handleShowLocationClick = useCallback(() => action(makeFeedLocationOpenedAction), [action]);

    const handleSetMapClick = useCallback(() => setFeedScreenState(FeedScreenState.MAP), [setFeedScreenState]);

    const handleFeedCarouselBannerClick = useCallback((selectedCarouselBanner: BannerCarouselItemDto) => {
        selectedCarouselBanner.url != null && window.location.replace(selectedCarouselBanner.url);
    }, []);

    const handleMapClose = useCallback(() => setFeedScreenState(FeedScreenState.LIST), [setFeedScreenState]);

    const handleMenuOpen = useCallback(() => action(makeUserMenuShowAction), [action]);

    const isFullIcon = useMediaQuery(theme.breakpoints.up(480));

    const router = useRouter();
    const handleSearchClick = useCallback(() => router.push('/search'), [router]);

    const windowDimensions = useWindowDimensions();
    const isHideBanner = windowDimensions.height < windowDimensions.width && windowDimensions.width < 720;

    const [bannerItemsToDisplay, setBannerItemsToDisplay] = useState(bannerItems);
    useMounted(() => {
        if (!isDataLoaded) {
            const bannerItemsUrl = makeUrl(
                Endpoint.FEED_CAROUSEL,
                {},
                { tag: preselectedTag, region: encodeURI(region), language },
            );
            const country = browserCookies.get('country');
            const headers = country ? { 'X-Country-Code': country } : {};
            return getRequest(bannerItemsUrl, headers)
                .then((response: { data: { carouselData: BannerCarouselItemDto[] } }) => {
                    setBannerItemsToDisplay(response.data.carouselData);
                })
                .catch(console.log);
        }
    });

    return (
        <div className={css.mainContainer}>
            <AddToHomeScreen />
            <div className={css.header}>
                <Hidden xsDown={feedScreenState === FeedScreenState.MAP} implementation="css">
                    <NoSsr>
                        <FeedHeader
                            isFullIcon={isFullIcon}
                            isFromSsr
                            openUserMenu={handleOpenUserMenu}
                            onSearchClick={handleSearchClick}
                            onCreateStoreClick={handleCreateStoreClick}
                            onMapClick={handleShowLocationClick}
                        />
                    </NoSsr>
                </Hidden>
            </div>
            <div className={feedScreenState === FeedScreenState.MAP ? css.mapRoot : css.desktop}>
                <NoSsr>
                    <Hidden mdDown>
                        <div className={css.map}>
                            {initialMapBounds && (
                                <FeedMap
                                    initialBounds={initialMapBounds}
                                    selectedCategory={selectedCategory}
                                    preselectedShopInfo={preselectedShopInfo}
                                    isAlreadyPreselectedShop={isAlreadyPreselectedShop}
                                    preselectedShopStatusChange={setIsAlreadyPreselectedShop}
                                    preselectedTag={preselectedTag || undefined}
                                />
                            )}
                        </div>
                    </Hidden>
                </NoSsr>
                <div className={css.feed}>
                    <Hidden lgUp implementation="css">
                        {feedScreenState === FeedScreenState.LIST && <CurrentLocation mapMode={false} />}
                    </Hidden>
                    {feedScreenState === FeedScreenState.MAP && (
                        <NoSsr>
                            <Hidden xsDown>
                                <div className={css.category}>
                                    <div className={css.categories}>
                                        <ShopCategoryFilter
                                            dynamicCategories={dynamicCategories}
                                            selectedCategory={selectedCategory}
                                            onSelect={onCategorySelect}
                                        />
                                    </div>
                                </div>
                            </Hidden>
                        </NoSsr>
                    )}
                    <div className={classNames(css.lists, feedScreenState === FeedScreenState.MAP && css.listsForMap)}>
                        {feedScreenState === FeedScreenState.MAP ? (
                            <div className={css.mapStateContainer}>
                                <div className={css.mapState}>
                                    <NoSsr>
                                        <FeedMap
                                            onClose={handleMapClose}
                                            onOpenMenu={handleMenuOpen}
                                            selectedCategory={selectedCategory}
                                            preselectedShopInfo={preselectedShopInfo}
                                            isAlreadyPreselectedShop={isAlreadyPreselectedShop}
                                            preselectedShopStatusChange={setIsAlreadyPreselectedShop}
                                            preselectedTag={preselectedTag || undefined}
                                        />
                                        <Hidden smUp implementation="css">
                                            <div className={classNames(css.categories, css.mapCategories)}>
                                                <ShopCategoryFilter
                                                    dynamicCategories={dynamicCategories}
                                                    selectedCategory={selectedCategory}
                                                    onSelect={onCategorySelect}
                                                />
                                            </div>
                                        </Hidden>
                                    </NoSsr>
                                </div>
                                <NoSsr>
                                    <CustomerMenu
                                        navigationPath={CustomerMenuSelectedItem.MAIN_PAGE}
                                        customStyles={css.mapCustomerMenu}
                                    />
                                </NoSsr>
                            </div>
                        ) : hasFeed ? (
                            <>
                                {!isHideBanner && (
                                    <BannerCarousel
                                        carouselItems={bannerItemsToDisplay}
                                        onBannerClick={handleFeedCarouselBannerClick}
                                    />
                                )}
                                <div className={css.category}>
                                    <NoSsr>
                                        <div className={css.categories}>
                                            <ShopCategoryFilter
                                                dynamicCategories={dynamicCategories}
                                                selectedCategory={selectedCategory}
                                                onSelect={onCategorySelect}
                                            />
                                        </div>
                                    </NoSsr>
                                </div>

                                {/*{!isSmallScreen && (*/}
                                {/*    <div>*/}
                                {/*        <div className={classes.text}>*/}
                                {/*            <Text typography={TextTypography.PARA_STRONG}>{t('feed:feed:new')}</Text>*/}
                                {/*        </div>*/}
                                {/*        <ShopCarousel items={shopCarouselItems} />*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {hasShopList ? (
                                    <>
                                        <div ref={infShopWrapper} className={css.infoShop}>
                                            {/*{!isSmallScreen && (*/}
                                            {/*    <div className={classes.text}>*/}
                                            {/*        <Text typography={TextTypography.PARA_STRONG}>*/}
                                            {/*            {t('feed:feed:nearest')}*/}
                                            {/*        </Text>*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            <InfiniteShopList
                                                items={infiniteShopList}
                                                hasMore={hasMore}
                                                fetchMoreData={fetchMoreData}
                                            />
                                        </div>
                                        {isMobileScreen && (
                                            <div className={css.mapButtonPos}>
                                                <div className={css.mapButton} onClick={handleSetMapClick}>
                                                    <Icon component={MapIcon} size={16} svgSize={16} color={'white'} />
                                                    <Text
                                                        className={css.mapButtonText}
                                                        typography={TextTypography.PARA_ACCENT}
                                                    >
                                                        {t('feed:feed:mapButton')}
                                                    </Text>
                                                </div>
                                            </div>
                                        )}
                                        <FeedFooter />
                                    </>
                                ) : (
                                    !isInfiniteShopListLoading && <EmptyFeed />
                                )}
                            </>
                        ) : (
                            !isInfiniteShopListLoading && <EmptyFeed />
                        )}
                    </div>
                </div>
            </div>
            <div className={css.footer}>
                {feedScreenState === FeedScreenState.LIST && (
                    <NoSsr>
                        <CustomerMenu
                            navigationPath={CustomerMenuSelectedItem.MAIN_PAGE}
                            customStyles={css.mapCustomerMenu}
                        />
                    </NoSsr>
                )}
            </div>
        </div>
    );
};
