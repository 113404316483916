import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Loader } from 'components/Loader/Loader';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ShopShortInfo, ShopShortInfoProps } from 'components/ShopShortInfo/ShopShortInfo';

import { StoreCategory, StoreDeliveryDto } from 'types';
import { InfiniteScrollWithLoadMoreButton } from './InfiniteScrollWithLoadMoreButton';

export interface FeedShops {
    nextStart: string;
    storeCards: StoreCard[];
}

export interface StoreCard {
    image: {
        defaultImage: boolean;
        imageId: string;
        sizeKb: 0;
        type: string;
    };
    coverImage: {
        defaultImage: boolean;
        imageId: string;
        sizeKb: 0;
        type: string;
    };
    distance: number;
    geoCoordinates: { latitude: number; longitude: number };
    name: string;
    storeId: string;
    customUrl: string;
    storeDelivery: StoreDeliveryDto[];
    storeCategory: StoreCategory;
    tags: [
        {
            name: string;
        },
    ];
    sellingDescription?: string;
    slogan: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 64px',
        overflow: 'hidden !important',
        [theme.breakpoints.down(960)]: {
            margin: '0 40px',
        },
        [theme.breakpoints.down(768)]: {
            margin: '0 24px',
        },
        [theme.breakpoints.down(480)]: {
            margin: '0 16px',
        },
    },
    shop: {
        paddingBottom: theme.spacing(2),
        flex: '0 0 100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 24,
        [theme.breakpoints.up(640)]: {
            flex: '0 0 47%',
            maxWidth: '47%',
            '&:nth-child(2n-1)': {
                justifyContent: 'flex-start',
                margin: 0,
            },
            '&:nth-child(2n)': {
                justifyContent: 'flex-end',
                margin: 0,
                marginLeft: 'auto',
            },
        },
        [theme.breakpoints.up(960)]: {
            flex: '0 0 30%',
            maxWidth: '30%',
            '&:nth-child(3n-2)': {
                justifyContent: 'flex-start',
                margin: 0,
            },
            '&:nth-child(3n-1)': {
                justifyContent: 'center',
                margin: '0 auto',
            },
            '&:nth-child(3n-1):last-child': {
                justifyContent: 'center',
                margin: '0 auto',
                marginLeft: '5%',
            },
            '&:nth-child(3n)': {
                justifyContent: 'flex-end',
                margin: 0,
            },
        },
        [theme.breakpoints.up(1280)]: {
            flex: '0 0 47%',
            maxWidth: '47%',
            '&:nth-child(2n-1)': {
                justifyContent: 'flex-start',
                margin: 0,
            },
            '&:nth-child(2n)': {
                justifyContent: 'flex-end',
                margin: 0,
                marginLeft: 'auto',
            },
        },
        [theme.breakpoints.up(1920)]: {
            flex: '0 0 30%',
            maxWidth: '30%',
            '&:nth-child(3n-2)': {
                justifyContent: 'flex-start',
                margin: 0,
            },
            '&:nth-child(3n-1)': {
                justifyContent: 'center',
                margin: '0 auto',
            },
            '&:nth-child(3n-1):last-child': {
                justifyContent: 'center',
                margin: '0 auto',
                marginLeft: '5%',
            },
            '&:nth-child(3n)': {
                justifyContent: 'flex-end',
                margin: 0,
            },
        },
    },
}));

export interface InfiniteShopListProps {
    hasMore: boolean;
    items: ShopShortInfoProps[];
    fetchMoreData: (v: boolean) => void;
}

export const InfiniteShopList = ({ items, hasMore, fetchMoreData }: InfiniteShopListProps) => {
    const classes = useStyles();

    const fetchMore = useCallback(() => fetchMoreData(false), [fetchMoreData]);

    return (
        <ErrorBoundary>
            <InfiniteScrollWithLoadMoreButton
                fetchMore={fetchMore}
                hasMore={hasMore}
                loader={<Loader />}
                className={classes.container}
            >
                {items.map((item: ShopShortInfoProps, i) => (
                    <ShopShortInfo
                        key={i}
                        className={classes.shop}
                        storeId={item.storeId}
                        imageId={item.imageId}
                        coverImageId={item.coverImageId}
                        name={item.name}
                        categories={item.categories}
                        storeCategory={item.storeCategory}
                        distance={item.distance}
                        customUrl={item.customUrl}
                        deliveryText={item.deliveryText}
                        sellingDescription={item.sellingDescription}
                        slogan={item.slogan}
                    />
                ))}
            </InfiniteScrollWithLoadMoreButton>
        </ErrorBoundary>
    );
};
